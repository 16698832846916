<template>
  <b-col class="pl-0 pr-0">
    <b-list-group-item class="list-sub-row py-2">
      <b-row>
          <b-col cols="10" class="pr-0">
            <b-form-checkbox :id="'computer-' + computer.id" @change="selectComputer" inline class="mr-auto">
              <span class="mr-2 noselect">{{ computer.displayname }}</span>
              <b-icon-arrow-up-circle-fill v-if="computer.online" :variant="variantSuccess"/>
              <b-icon-arrow-down-circle-fill v-else :variant="variantDanger"/>
            </b-form-checkbox>
          </b-col>
          <b-col cols="2" class="pl-2">
            <CustomerInfo :computer="computer"/>
          </b-col>
      </b-row>
    </b-list-group-item>
  </b-col>
</template>

<script>
import {mapGetters} from "vuex";
import CustomerInfo from "@/components/lists/groupList/utility/CustomerInfo.vue";

export default {
  name: "ComputerInfo",
  components: {
    CustomerInfo
  },
  props: ['computer'],
  methods: {
    selectComputer() {
      this.$emit('select-computer');
    }
  },
  computed: {
    ...mapGetters(['userThemeId']),
    variantSuccess() {
      if(this.userThemeId === 3) { return 'success-deuteranopia' }
      return 'success'
    },
    variantDanger() {
      if(this.userThemeId === 3) { return 'danger-deuteranopia' }
      return 'danger'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
